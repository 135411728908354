import E from 'wangeditor';
import { uploadFile } from "@/api/api.js";
var editor;
export default {
  name: 'editor',
  props: ['get-full-text', 'content', 'no'],
  //回调方法
  mounted() {
    editor = new E(this.$refs.editor);
    editor.config.onchange = html => {
      //console.log(html)
      this.getFullText1(html);
    };
    //editor.config.height=600;
    //editor.$textContainer('height','700px !important')
    editor.config.border = 0;
    editor.config.minHeight = '16vw';
    editor.config.maxHeight = '16vw';
    editor.config.height = '16vw';
    editor.config.uploadImgServer = '/api/upload/';
    editor.config.uploadFileName = 'file';
    editor.config.zIndex = 100;
    editor.config.uploadImgFns = function onload(resultText, xhr) {
      console.log(resultText, xhr);
    };
    editor.config.customUploadImg = async (resultFiles, insertImg) => {
      let file = resultFiles[0];
      console.log(file);
      let param = new FormData(); // 创建form对象
      param.append("file", file); // 通过append向form对象添加数据
      uploadFile(param).then(res => {
        insertImg('/api/' + res.data);
      });
    };
    editor.txt.html(this.content);
    editor.create();
    //如果默认传递content值则渲染默认内容
  },

  methods: {
    getFullText1(html) {
      this.$emit('getText', html);
    },
    setText(text) {
      editor.txt.html('');
      editor.txt.html(text);
    }
  }
};